import { Injectable, Inject } from '@angular/core'
import { SeServiceBase } from '../se-service'
import { ParticipationOption } from './participation-option'

@Injectable({ providedIn: 'root' })
export class ParticipationOptionService extends SeServiceBase<ParticipationOption> {

  public endpoint = 'v3/program/participation_options'
  public Model: any = ParticipationOption

}
