import { Injectable, Inject } from '@angular/core'
import { SeServiceBase } from '../se-service'
import { FeatureToggle } from './feature-toggle'

export interface FeatureToggleMap {
  [key: string]: string|boolean
}

export interface FeatureToggleCache {
  [orgId: string]: Promise<FeatureToggleMap>
}

@Injectable({ providedIn: 'root' })
export class FeatureToggleService extends SeServiceBase<FeatureToggle> {
  public endpoint = 'feature_instances'
  public Model: any = FeatureToggle
  private cache: FeatureToggleCache = {}

  public async allEnabledForOrg(orgId: number|string, toggles: string[]): Promise<boolean> {
    for (const toggle of toggles) {
      const enabled = await this.enabledForOrg(orgId, toggle)
      if (!enabled) return false
    }
    return true
  }

  public async enabledForOrg(orgId: number|string, toggle: string): Promise<boolean> {
    const featureToggles = await this.forOrg(orgId)
    return !!featureToggles[toggle]
  }

  public async forOrg(orgId: number|string): Promise<FeatureToggleMap> {
    const id = orgId.toString()
    return this.cache[id] = this.cache[id] || this.loadForOrg(id)
  }

  private async loadForOrg(orgId): Promise<any> {
    const featureToggles = {}
    const rawFeatureToggles = await this.findAll({ organization_id: orgId })
    rawFeatureToggles.forEach(feature => {
      if (feature.can_use) featureToggles[feature.key] = feature.release_stage || true
    })
    return featureToggles
  }
}
