import { SeMappableModel, SeServiceBase } from '../se-service'

export class ElectronicDocument extends SeMappableModel {
  public archived_at: string
  public boss_organization_id: number
  public created_at: string
  public created_by: number
  public current_version: ElectronicDocumentVersion
  public current_version_number: number
  public id: string
  public published_at: string
  public waiver_id: string
  public versions: Partial<ElectronicDocumentVersion>[]
}

export interface ElectronicDocumentVersion {
  acceptance_text: string
  archived_at: string
  body: string
  created_at: string
  created_by: number
  created_by_user_full_name: string
  id: string
  published_at: string
  title: string
  updated_at: string
  updated_by: number
  version: number
}
