import { NgModule } from '@angular/core'
import { SeFeModalModule } from 'se-fe-modal'
import { ConfirmComponent } from './confirm.component'
import { ConfirmService } from './confirm.service'

@NgModule({
  imports: [SeFeModalModule],
  declarations: [ConfirmComponent],
  exports: [ConfirmComponent],
  providers: [ConfirmService]
})
export class ConfirmModule { }
