import { Injectable } from '@angular/core'
import { SeServiceBase } from '../se-service'
import { EligibilityRuleSet } from './eligibility-rule-set'

@Injectable({ providedIn: 'root' })
export class EligibilityRuleSetsService extends SeServiceBase<EligibilityRuleSet> {

  public endpoint = 'eligibility_rule_sets'
  public Model: any = EligibilityRuleSet

}
