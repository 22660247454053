import { Injectable } from '@angular/core'
import { SeServiceBase } from '../se-service'
import { SeApi } from '@se-api'
import { ParticipationRequirement } from './participation-requirement'

@Injectable({ providedIn: 'root' })
export class ParticipationRequirementsService extends SeServiceBase<ParticipationRequirement> {

  public endpoint = 'v3/program/participation_requirements'
  public Model: any = ParticipationRequirement

  constructor(
    public api: SeApi,
  ) {
    super(api)
  }
}
