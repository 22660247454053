import { NgModule } from '@angular/core'
import { environment } from '../../environments/environment'
import { FeatureToggleModule } from '../feature-toggle'
import { SeFeAuthSupportModule } from 'se-fe-auth-support'
import {
  AppPermissionService,
  ProgramEditPermission,
  ProgramViewPermission,
} from './app-permission.service'

@NgModule({
  imports: [
    FeatureToggleModule,
    SeFeAuthSupportModule,
  ],
  providers: [
    AppPermissionService,
    ProgramEditPermission,
    ProgramViewPermission,
  ]
})
export class AppPermissionModule { }
