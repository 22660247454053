import { Injectable } from '@angular/core'
import { SeServiceBase } from '../se-service'
import { TranslateService } from '@ngx-translate/core'
import { SeApi } from '@se-api'
import { SeFeDateTime } from 'se-fe-date-time'
import { ParticipationLimitConfiguration, ParticipationLimits } from './participation-limit-configuration'

@Injectable({ providedIn: 'root' })
export class ParticipationLimitConfigurationService extends SeServiceBase<ParticipationLimitConfiguration> {

  public endpoint = 'v3/program/participation_limit_configurations'
  public Model: any = ParticipationLimitConfiguration

  private noAgeLimit: string
  private noGenderLimit: string
  private noLimits: string

  static createNestedPLC(data: any, limitableType: string): any {
    const defaultConfigData = { limitable_type: limitableType, limitable_id: data.id }
    const configData = data.participation_limit_configuration || defaultConfigData
    const config = this.createInstance(ParticipationLimitConfiguration, configData)
    data.participation_limit_configuration = config
    return data
  }

  constructor(
    public api: SeApi,
    public translate: TranslateService,
  ) {
    super(api)
    this.noAgeLimit = this.translate.instant('PARTICIPATION_LIMITS.no_age_limit')
    this.noGenderLimit = this.translate.instant('PARTICIPATION_LIMITS.no_gender_limit')
    this.noLimits = this.translate.instant('PARTICIPATION_LIMITS.no_limits')
  }

  public saveLimits(plc: ParticipationLimitConfiguration, limits: ParticipationLimits): Promise<ParticipationLimitConfiguration> {
    const ageLimitRequired = !!limits.age && !!(limits.age?.min_date_of_birth || limits.age?.max_date_of_birth)
    const genderLimitRequired = !!limits.gender && !!limits.gender?.allowed_gender
    const data: Partial<ParticipationLimitConfiguration> = {
      id: plc.id,
      limitable_type: plc.limitable_type,
      limitable_id: plc.limitable_id,
      age_limit_required: ageLimitRequired,
      gender_limit_required: genderLimitRequired,
    }

    if (ageLimitRequired) data.age_limit_attributes = limits.age
    if (genderLimitRequired) data.gender_limit_attributes = limits.gender

    return this.save(data)
  }

  public ageLimitDisplay(config: ParticipationLimitConfiguration): string {
    if (!config.age_limit) return this.noAgeLimit

    const dates = {
      min: (new SeFeDateTime(config.age_limit.min_date_of_birth)).shortDate,
      max: (new SeFeDateTime(config.age_limit.max_date_of_birth)).shortDate,
    }

    if (!dates.min && !dates.max) return this.noAgeLimit
    if (dates.min && dates.max) return this.translate.instant('PARTICIPATION_LIMITS.born', dates)
    if (dates.min) return this.translate.instant('PARTICIPATION_LIMITS.born_after', dates)
    if (dates.max) return this.translate.instant('PARTICIPATION_LIMITS.born_before', dates)
  }

  public genderLimitDisplay(config: ParticipationLimitConfiguration): string {
    const gender = config.gender_limit?.allowed_gender
    return gender ? this.translate.instant(`PARTICIPATION_LIMITS.${gender}`) : this.noGenderLimit
  }

}
