import { SeMappableModel } from '../se-service'
import { pick } from 'lodash'

export interface AgeLimit {
  id?: number
  max_date_of_birth: string
  min_date_of_birth: string
}

export interface GenderLimit {
  id?: number
  allowed_gender: string
}

export interface ParticipationLimits {
  age?: AgeLimit,
  gender?: GenderLimit,
}

export class ParticipationLimitConfiguration extends SeMappableModel {

  static REQUIRED_PROPERTIES = [
    'age_limit_required',
    'gender_limit_required'
  ]

  static BASE_PROPERTIES = [
    'id',
    'limitable_type',
    'limitable_id',
    ...ParticipationLimitConfiguration.REQUIRED_PROPERTIES
  ]

  public id: number
  public limitable_id: number
  public limitable_type: string
  public age_limit_required: boolean
  public age_limit: AgeLimit
  public age_limit_attributes?: AgeLimit
  public gender_limit: GenderLimit
  public gender_limit_required: boolean
  public gender_limit_attributes?: GenderLimit

  public get baseProperties(): Partial<ParticipationLimitConfiguration> {
    return pick(this, ParticipationLimitConfiguration.BASE_PROPERTIES)
  }

  public limitSet(property: string): boolean {
    return typeof this[property] === 'boolean'
  }

  public limitsRequired(): boolean {
    for (const property of ParticipationLimitConfiguration.REQUIRED_PROPERTIES) {
      if (this.limitRequired(property)) return true
    }
    return false
  }

  public limitRequired(property: string): boolean {
    return !!this[property]
  }

  public anyLimitsSet(): boolean {
    for (const property of ParticipationLimitConfiguration.REQUIRED_PROPERTIES) {
      if (this.limitSet(property)) return true
    }
    return false
  }

  public allLimitsSet(): boolean {
    for (const property of ParticipationLimitConfiguration.REQUIRED_PROPERTIES) {
      if (!this.limitSet(property)) return false
    }
    return true
  }

  public limitEnabled(property: string): boolean {
    return this[property] === true
  }

  public limitsEnabled(): boolean {
    for (const property of ParticipationLimitConfiguration.REQUIRED_PROPERTIES) {
      if (this.limitEnabled(property)) return true
    }
    return false
  }

}

export interface ParticipationLimitConfigurationData extends Partial<ParticipationLimitConfiguration> {
  // TODO: remove this and replace with `Partial<ParticipationLimitConfiguration>`
}
