import { SeMappableModel, SeServiceBase } from '../se-service'

export class OrganizationSport extends SeMappableModel {

  static DEFAULT = SeServiceBase.createInstance(OrganizationSport, { label: 'Other', key: 'other' })

  public value: number
  public label: string
  public key: string
}
