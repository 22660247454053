import { SeMappableModel, SeCollection, SeServiceBase } from '../se-service'
import { ParticipationOption } from './participation-option'
import { ParticipantType } from './participant-type'
import { ParticipationLimitConfiguration } from './participation-limit-configuration'
import { ParticipationLimitConfigurationService } from './participation-limit-configuration.service'
import { ParticipationRequirement } from './participation-requirement'

export class ProgramParticipantType extends SeMappableModel {
  public id: number
  public program_id: number
  public participant_type_id?: number // full object is in the response, but the id is used to save
  public participant_type: ParticipantType
  public has_age_limits: boolean
  public has_gender_limits: boolean
  public has_signups: boolean
  public item_uuid: string
  public participation_limit_configuration: ParticipationLimitConfiguration
  public participation_requirement: ParticipationRequirement
  public participationOptions: SeCollection<ParticipationOption>

  public limitSet(property: string): boolean {
    return this.participation_limit_configuration.limitSet(property)
  }

  public limitsRequired(): boolean {
    return this.participation_limit_configuration.limitsRequired()
  }

  public limitRequired(property: string): boolean {
    return this.has_signups && this.participation_limit_configuration.limitRequired(property)
  }

  public anyLimitsSet(): boolean {
    return this.has_signups && this.participation_limit_configuration.anyLimitsSet()
  }

  public allLimitsSet(): boolean {
    return !this.has_signups || this.participation_limit_configuration.allLimitsSet()
  }

  public participationRequirementSet(): boolean {
    return !!(this.participation_requirement && this.participation_requirement.has_edocs !== null)
  }

  public eDocsEnabled(): boolean {
    return this.participationRequirementSet() && !!this.participation_requirement.has_edocs
  }

  public deserialize(data: any): any {
    const pt = data.participant_type
    if (pt) data.participant_type = SeServiceBase.createInstance<ParticipantType>(ParticipantType, pt)
    return ParticipationLimitConfigurationService.createNestedPLC(data, 'ProgramParticipantType')
  }

}
