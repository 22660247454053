import { gtmConfig } from '../gtm'

export const environment = {
  name: 'staging',
  apiUrl: 'https://api.stage.ngin-staging.com/',
  clientId: '1b76c322ad5fba6b98b4b530bc6d9dc5',
  defaultUrl: '/',
  electronicDocumentsUrl: 'https://electronic-documents.ui.sestage.us/',
  financialAdminLibraryUrl: 'https://se-financial-admin.ui.sestage.us',
  seFeFrameRouterOrigin: ['https://app.stage.ngin-staging.com', 'https://org.ui.stage.ngin-staging.com', 'https://org.stage.ngin-staging.com'],
  launchDarklyClientId: '5f4fc4507c0c78095ceeb4ed',
  production: false,
  staging: true,
  userUrl: 'https://user.stage.ngin-staging.com/',
  gtm: gtmConfig,
  orgUrl: 'https://org.stage.ngin-staging.com/org/'
}
