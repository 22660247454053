import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { TranslationsPrefix, TranslationsLanguages } from './translations-injection-tokens'

@Injectable({ providedIn: 'root' })
export class TranslationsLoader implements CanActivate {

  private loaded: { [scope: string]: boolean } = {}
  private translations: any = {}

  constructor(
    @Inject(TranslationsPrefix) private prefix: string,
    @Inject(TranslationsLanguages) private langs: string[],
    private translateService: TranslateService,
    private http: HttpClient
  ) {
    // noop
  }

  // A resolver is used to preload translations for each component, since there seems to be a bug
  // with the httpLoader that doesn't do this automatically when we extend from the parent translate
  // service. We want to track the load anyway to prevent components from trying to load before
  // the translation data is ready, so this serves both purposes.
  public canActivate(router: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Promise<boolean> {
    if (this.loaded[this.prefix]) return

    this.loaded[this.prefix] = true
    this.setCurrentLang()

    return this.http
      .get(`${this.prefix}/${this.lang}.json`)
      .toPromise()
      .then(translations => {
        this.translateService.setTranslation(this.lang, translations, true)
        return true
      })
  }

  private setCurrentLang(): void {
    if (this.translateService.currentLang) return

    const possibleLangs = [
      this.translateService.getBrowserCultureLang(),
      this.translateService.getBrowserLang(),
      this.translateService.defaultLang // 'en'
    ]

    while (possibleLangs.length) {
      const lang = possibleLangs.shift()
      if (this.langs.includes(lang)) {
        this.translateService.use(lang)
        return
      }
    }
  }

  private get lang(): string {
    return this.translateService.currentLang || this.translateService.defaultLang
  }
}
