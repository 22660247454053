import { Injectable } from '@angular/core'
import {
  ActivationEnd,
  RoutesRecognized,
  NavigationEnd,
  NavigationCancel,
  Router,
  RouterEvent,
} from '@angular/router'
import { Program } from '@app-services'
import { SeFeAnalytics } from 'se-analytics-js'
import { environment } from '../../environments/environment'

interface ProgramObj {
  boss_organization_name?: string
  boss_organization_id?: number
  program_id?: number
  program_name?: string
  program_sport?: string
  program_type?: string
}

const MAX_DEPTHS = 5

@Injectable({ providedIn: 'root' })
export class AnalyticsService {

  private pageDepths: any
  private program: Partial<Program>
  private newDepths: string[] = []
  private navCanceled: boolean
  private analytics: SeFeAnalytics = new SeFeAnalytics({ platProp: 'program_ui', platformSubSec: 'HQ', currentEnv: environment.name })

  constructor(
    private router: Router,
  ) {
    this.router.events.subscribe(this.handleRouterEvent.bind(this))
  }

  // PUBLIC METHODS

  public pageView(
    pageDepths: any,
    program?: Partial<Program>
  ): void {
    const data = {...pageDepths, program: this.parseProgram(program)}
    this.analytics.push({ type: 'pageView', data })
  }

  public triggerEvent(action: string, value?: number, program?: Partial<Program>): void {
    const data = { event_type: 1, action, value, program: this.parseProgram(program), ...this.pageDepths }
    this.analytics.push({ type: 'seEvent', data })
  }

  public setProgram(program: Program): void {
    this.program = program
  }

  public clearProgram(): void {
    delete this.program
  }

  // PRIVATE METHODS

  private handleRouterEvent(event: RouterEvent) {
    // We are using RoutesRecognized to reset depth tracking becasue it works better
    // than NavigationStart. RoutesRecognized fires early in the navigation lifecycle
    // and will fire again if a second navigate call fires before the first
    // is complete. NavigationStart is only called the first time.
    if (event instanceof RoutesRecognized) {
      this.newDepths.length = 0
      this.navCanceled = false
    } else if (event instanceof NavigationCancel) {
      this.navCanceled = true
    } else if (event instanceof ActivationEnd) {
      this.captureDepths(event)
    } else if (event instanceof NavigationEnd) {
      this.triggerPageview()
    }
  }

  private captureDepths(event: ActivationEnd): void {
    const data = event.snapshot.data || {}
    const depths = (data.pageDepth || '').split('.').reverse()
    const nd = this.newDepths
    depths.forEach(d => nd.includes(d) || nd.unshift(d))
  }

  private triggerPageview(): void {
    if (this.navCanceled) return
    this.pageDepths = this.calculatePageDepths(this.newDepths)
    this.pageView(this.pageDepths, this.program)
  }

  private parseProgram(program: Partial<Program>): ProgramObj {
    program ||= this.program
    if (!program) return {}
    return {
      boss_organization_id: program.organization_id,
      program_id: program.id,
      program_name: program.name,
      program_sport: program.sport_key,
      program_type: program.program_type.name,
    }
  }

  private calculatePageDepths(values: string[]): any {
    const last = values.slice(MAX_DEPTHS).join('.')
    values = values.slice(0, MAX_DEPTHS - 1).concat(last)

    return {
      depth1: values[0],
      depth2: values[1],
      depth3: values[2],
      depth4: values[3],
      depth5: values[4]
    }
  }

}
