import { Injectable } from '@angular/core'
import { AppPermission } from './app-permission'

// Define AppPermission instances that can be used as route guards or as template observables

@Injectable({ providedIn: 'root' })
export class ProgramViewPermission extends AppPermission {
  protected config = {
    featureToggles: ['platform_programs'],
    allowAdmins: true,
    allowRoles: {
      Organization: ['org_admin']
    }
  }
}

@Injectable({ providedIn: 'root' })
export class ProgramEditPermission extends ProgramViewPermission { }

// Expose AppPermission instances via the AppPermissionService for controller/template injection

@Injectable({ providedIn: 'root' })
export class AppPermissionService {

  constructor(
    private programEditPermission: ProgramEditPermission,
    private programViewPermission: ProgramViewPermission,
  ) {
    // noop
  }

}
