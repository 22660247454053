import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import startCase from 'lodash/startCase'

const MISSING_TOKEN = /\{\{(.+)\}\}/g

@Injectable({ providedIn: 'root' })
export class AppTitleService {

  constructor(
    private translateService: TranslateService
  ) {
    // noop
  }

  // Translate strings and replace missing tokens with their human-readable alternative.
  // VALUE    "{{program_signup}} Info"
  // BECOMES  "2022 Player Signup Info"
  // OR       "Program Signup Info"
  public translate(key: string, tokens: any): string {
    return this.translateService
      .instant(key, tokens)
      .replace(MISSING_TOKEN, (match: string, token: string) => startCase(token))
  }

}
