import { Injectable, Inject } from '@angular/core'
import { SeApi } from '../se-api'
import { SeServiceBase } from '../se-service'
import { Organization } from './organization'
import { CURRENT_ORG_ID } from '../current-org-id.provider'

export type OrganizationOptions = any // TEMP

@Injectable({ providedIn: 'root' })
export class OrganizationService extends SeServiceBase<Organization> {

  public endpoint = 'organizations'
  public Model: any = Organization
  private currentPromise: Promise<Organization>
  private optionsPromise: Promise<OrganizationOptions> // cache org options since they basically never change

  constructor(
    @Inject(CURRENT_ORG_ID) protected currentOrgId: string,
    public api: SeApi
  ) {
    super(api)
  }

  public current(): Promise<Organization> {
    return this.currentPromise = this.currentPromise || this.find(this.currentOrgId)
  }

  public options() {
    return this.optionsPromise = this.optionsPromise || this.getOptions()
  }

  private getOptions() {
    return this.api.get('/organization_options').toPromise().then(resp => resp.result)
  }

}
