import { NgModule } from '@angular/core'

import { AnalyticsModule } from './analytics'
import { AppPermissionModule } from './app-permission'
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { RouterModule } from '@angular/router'

import { SeFeFrameRouterModule, SeFeFrameRouterTitleService } from 'se-fe-frame-router'
import { SeApiModule } from './se-api'
import { SeFeAuthSupportModule } from 'se-fe-auth-support'
import { SeFeToastModule } from 'se-fe-toast'
import { TranslationsModule } from './translations'
import { ConfirmModule } from './confirm'

import { AppComponent } from './app.component'
import { NotFoundComponent } from './not-found.component'

import { AppTitleService } from './app-title.service'
import { LaunchDarkly } from '@app-services'

import { environment } from '../environments/environment'
import { baseHrefProvider } from './base-href.provider'
import { routes } from './routes'

import { SeFeButtonModule } from 'se-fe-button'

@NgModule({
  imports: [
    AnalyticsModule,
    TranslationsModule.forRoot(['en']),
    AppPermissionModule,
    BrowserModule,
    CommonModule,
    ConfirmModule,
    SeFeFrameRouterModule.forRoot({
      origin: environment.seFeFrameRouterOrigin,
      overlaySelector: '.se-fe-modal--open, app-wizard',
      overlayDelay: 300,
      titleServiceClass: AppTitleService,
    }),
    HttpClientModule,
    SeFeButtonModule,
    SeFeToastModule,
    SeFeAuthSupportModule.forRoot(environment),
    SeApiModule.forRoot(environment),
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }),
  ],
  exports: [
    AnalyticsModule,
    RouterModule,
  ],
  providers: [
    LaunchDarkly,
    baseHrefProvider,
  ],
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
