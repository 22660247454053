import { Component, HostBinding, Inject, OnInit, ViewEncapsulation } from '@angular/core'
import { ProgramEditPermission } from './app-permission'
import { TranslationsLoader } from './translations'

import { environment } from '../environments/environment'
import { CURRENT_ORG_ID } from '@app'
import { AnalyticsService } from './analytics'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {

  constructor(
    @Inject(CURRENT_ORG_ID) protected currentOrgId: string,
    protected analyticsService: AnalyticsService,
    public programEditPermission: ProgramEditPermission
  ) {
    // noop
  }

}
