import { NgModule } from '@angular/core'
import { SeServiceModule } from '../se-service'
import { FeatureToggle } from './feature-toggle'
import { FeatureToggleService } from './feature-toggle.service'

@NgModule({
  imports: [
    SeServiceModule
  ],
  providers: [
    FeatureToggle,
    FeatureToggleService,
  ]
})
export class FeatureToggleModule { }
