import { Injectable } from '@angular/core'
import { SeServiceBase } from '@se-service'
import { ProgramSignupPaymentTermOption } from '@app-services'

@Injectable({ providedIn: 'root' })
export class ProgramSignupPaymentTermOptionService extends SeServiceBase<ProgramSignupPaymentTermOption> {

  public endpoint = 'v3/program/program_signup_payment_term_options'
  public Model: any = ProgramSignupPaymentTermOption

}
