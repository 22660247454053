import { Injectable } from '@angular/core'
import { SeApi } from '../se-api'
import { SeServiceBase, SeCollection } from '../se-service'
import { ProgramParticipantType } from './program-participant-type'
import { ParticipationOption } from './participation-option'
import { ParticipationOptionService } from './participation-option.service'

export interface ProgramParticipantTypeFinancialSummary {
  total_amount: number
  total_quantity: number
}

@Injectable({ providedIn: 'root' })
export class ProgramParticipantTypeService extends SeServiceBase<ProgramParticipantType> {

  public endpoint = 'v3/program/program_participant_types'
  public Model: any = ProgramParticipantType

  constructor(
    public api: SeApi,
    private participationOptionService: ParticipationOptionService
  ) {
    super(api)
  }

  public async findAllWithOptions(params?: any, options?: any): Promise<SeCollection<ProgramParticipantType>> {
    const ppts = await this.findAll(params, options)
    return Promise.all(ppts.map(async ppt => {
      ppt.participationOptions = await this.options(ppt)
      return ppt
    }))
  }

  public parseFindAll(data: any): SeCollection<ProgramParticipantType> {
    return super.parseFindAll(data).sort((ppt1, ppt2) => ppt1.participant_type.id < ppt2.participant_type.id ? -1 : 1)
  }

  public financialSummary(ppt: ProgramParticipantType): Promise<ProgramParticipantTypeFinancialSummary> {
    const options = {
      params: {
        item_id: ppt.item_uuid,
        sold: true,
        per_page: 1
      }
    }

    return this.api
      .get('sale_items', options)
      .toPromise()
      .then(resp => resp?.metadata?.summary)
  }

  public options(ppt: ProgramParticipantType): Promise<SeCollection<ParticipationOption>> {
    return this.participationOptionService.findAll({ program_participant_type_id: ppt.id })
  }

}
