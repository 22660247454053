import { SeMappableModel } from '../se-service'

export class ProgramType extends SeMappableModel {

  static LONG_DURATION_IDS = [1] // 1 = season

  public id: number
  public key: string
  public name: string
  public description: string

  public get season(): boolean {
    return this.key === 'season'
  }

  public get campClinic(): boolean {
    return this.key === 'camp_clinic'
  }

  public get tournament(): boolean {
    return this.key === 'tournament'
  }

  public get event(): boolean {
    return this.key === 'event'
  }
}
