import { Injectable } from '@angular/core'
import { DateTime } from 'luxon'

export const TIME_STEP_MIN = 10 // 10 minutes
export const TIME_STEP_DEFAULT = 30 // 30 minutes
export const TIME_STEP_MAX = (60 * 12) // 12 hours
export const TIME_FORMAT_12 = 'hh:mm a ZZZZ'
export const TIME_FORMAT_24 = 'HH:mm ZZZZ'

export interface TimeOption {
  label: string
  value: string
}

@Injectable({ providedIn: 'root' })
export class TimeSelectOptionService {
  public timeOptions: TimeOption[] = []

  public getTimeOptions(timeStep: number, timeZone: string, date?: string): TimeOption[] {
    let minutes = 0

    const oneDay = 60 * 24
    const step = Math.max(TIME_STEP_MIN, Math.min(timeStep, TIME_STEP_MAX)) // max of 12 hours between times
    const dateString = !!date ? DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd') : DateTime.now().toFormat('yyyy-MM-dd')

    this.timeOptions = []

    while (minutes < oneDay) {
      const hh = this.pad(`${(minutes - minutes % 60) / 60}`)
      const mm = this.pad(`${minutes % 60}`)
      const value = `${hh}:${mm}`
      const label = this.timeLabel(`${dateString}T${hh}:${mm}:00`, timeZone)

      this.timeOptions.push({ value, label })

      minutes += step
    }

    return this.timeOptions
  }

  public get timeFormat(): string {
    try {
      const dtf = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric' })
      const is24Hour = !dtf.format(0).match(/\s/)
      return is24Hour ? TIME_FORMAT_24 : TIME_FORMAT_12
    } catch (e) {
      return TIME_FORMAT_12
    }
  }

  private pad(num: string): string {
    const str = `0${num}`
    return str.substring(str.length - 2)
  }

  private timeLabel(timeString: string, timeZone: string): string {
    const dt = DateTime.fromISO(timeString, { zone: timeZone })
    return dt.toFormat(this.timeFormat) // TODO: consider making this available directly
  }

}
