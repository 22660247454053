import { SeMappableModel } from '../se-service'

export class FeatureToggle extends SeMappableModel {
  public id: number
  public label: string
  public key: string
  public enabled: boolean
  public can_use: boolean
  public release_stage: string
  public user_controlled: boolean
}
