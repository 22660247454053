export class SeMappableModel {

  public id: string|number
  public metadata: any = {}
  public errors: any

  public constructor(data: any) {
    this.update(data)
  }

  public update(data: any): void {
    Object.assign(this, this.deserialize(data))
  }

  // Override this to convert data into the proper format when coming from the api.
  // For example, to convert date strings to actual date object, or to hydrate nested models.
  public deserialize(data: any): any {
    return data
  }
}
