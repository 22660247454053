import { Injectable, Inject } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SeServiceBase, SeServiceUpdateEvent } from '../se-service'
import { SeApi } from '@se-api'
import { SeFeDateTime } from 'se-fe-date-time'
import { Program } from './program'
import { ParticipantSummaryService } from './participant-summary.service'

@Injectable({ providedIn: 'root' })
export class ProgramService extends SeServiceBase<Program> {

  public endpoint = 'v3/program/programs'
  public Model: any = Program

  constructor(
    public api: SeApi,
    public translate: TranslateService,
    private participantSummaryService: ParticipantSummaryService,
  ) {
    super(api)
  }

  public async findAll(params?: any, options?: any): ReturnType<SeServiceBase<Program>['findAll']> {
    const programs = await super.findAll(params, options)
    await Promise.all(programs.map(p => this.attachParticipantSummary(p)))
    return programs
  }

  public async find(id: string | number, options?: any): ReturnType<SeServiceBase<Program>['find']> {
    const program = await super.find(id, options)
    await this.attachParticipantSummary(program)
    return program
  }

  // This method is used to pre-fetch summary info to determine if a program can be deleted.
  // Since archived programs cannot be deleted, we exit early to save the network call.
  public async attachParticipantSummary(program: Program): Promise<void> {
    if (program.isArchived) return
    program.participant_summary = await this.participantSummaryService.byProgramId(program.id)
  }

  public updateStatus(id: number, status: string): Promise<Program> {
    const url = `${this.endpoint}/${id}/update_status`
    const request = this.api.put(url, { status }, this.includeApiOptions())
    return this.promiseModel(request, SeServiceUpdateEvent)
  }

  public subtitle(program: Program): string[] {
    return [this.programTypeDisplay(program), program.dateRangeDisplay]
  }

  public programTypeDisplay(program: Program): string {
    return this.translate.instant(`PROGRAM_TYPE.${program.program_type.key}`)
  }

}
