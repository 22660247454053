import { Component, OnInit, ViewChild } from '@angular/core'
import { SeFeModalComponent } from 'se-fe-modal'
import { ConfirmService } from './confirm.service'
import { ConfirmConfig, ConfirmAction } from './confirm.types'

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styles: [':host { display: block; }'],
})
export class ConfirmComponent implements OnInit {

  @ViewChild('modal') modal: SeFeModalComponent

  public config: ConfirmConfig

  constructor(
    private confirmService: ConfirmService,
  ) {
    // noop
  }

  public ngOnInit(): void {
    this.confirmService.events.subscribe(config => this.confirm(config))
  }

  private confirm(config: ConfirmConfig): void {
    if (!config) return
    this.config = this.wrap(config)
    this.modal.open()
  }

  private wrap(config: ConfirmConfig): ConfirmConfig {
    return {
      message: config.message,
      actions: config.actions.map(action => this.wrapAction(action))
    }
  }

  private wrapAction(original: ConfirmAction): ConfirmAction {
    const wrapped = { ...original }

    if (wrapped.destructive) wrapped.primary = true // TODO: remove once se-fe-modal supports destructive buttons
    if (!wrapped.primary || wrapped.destructive) wrapped.minimal = true

    // Wrap action to close modal when complete
    wrapped.action = async () => {
      const result = await original.action()
      this.modal.close()
      return result
    }

    return wrapped
  }

}
