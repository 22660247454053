import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SeServiceBase } from '@se-service'
import { SeApi } from '@se-api'
import { ProgramSignup } from './program-signup'

export interface ProgramSignupForm {
  key: string
  sections: ProgramSignupSection[]
}

export interface ProgramSignupSection {
  key: string
  fields: ProgramSignupFormField[]
}

export interface ProgramSignupFormField {
  key: string
  enabled: boolean
  answer_required: boolean
  question_required?: boolean
}

export class ProgramSignupConfiguration {
  id: number
  program_signup_id: number
  program_signup: ProgramSignup
  configuration_json: ProgramSignupForm[]
}

@Injectable({ providedIn: 'root' })
export class ProgramSignupConfigurationService extends SeServiceBase<ProgramSignup> {
  public endpoint = 'v3/program/program_signup_configurations'
  public Model: any = ProgramSignupConfiguration

  constructor(public api: SeApi) {
    super(api)
  }

  public configuration(programSignupId: number): Promise<ProgramSignupConfiguration> {
    return this.api
      .get(`${this.endpoint}?program_signup_id=${programSignupId}`)
      .toPromise()
      .then(res => res.result[0])
  }

  public defaultConfiguration(programSignupId: number): Promise<ProgramSignupForm[]> {
    return this.api
      .get(`${this.endpoint}/default_configuration?program_signup_id=${programSignupId}`)
      .toPromise()
      .then(res => res.result)
  }

  public create(programSignupId: number, configurationJson: ProgramSignupForm[]): Promise<ProgramSignupConfiguration> {
    return this.api
      .post(`${this.endpoint}?program_signup_id=${programSignupId}`, {
        configuration_json: JSON.stringify(configurationJson),
      })
      .toPromise()
      .then(res => res.result)
  }

  public update(id: number, programSignupConfiguration: ProgramSignupForm[]): Promise<ProgramSignupConfiguration> {
    return this.api
      .put(`${this.endpoint}/${id}`, {
        configuration_json: JSON.stringify(programSignupConfiguration),
      })
      .toPromise()
      .then(res => res.result)
  }
}
