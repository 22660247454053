import { Routes } from '@angular/router';
import { NotFoundComponent } from './not-found.component';
import { SeFeFrameRouterService } from 'se-fe-frame-router';
import { TranslationsLoader } from './translations';
import { LaunchDarkly } from '@app-services';

export const routes: Routes = [
  {
    path: '',
    data: {
      seFeFrameRouterTitle: 'TITLE.default',
      pageDepth: 'HQ.Programs',
    },
    canActivate: [TranslationsLoader, SeFeFrameRouterService, LaunchDarkly],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./program-list/program-list.module').then(
            (m) => m.ProgramListModule
          ),
      },
      {
        path: '**',
        component: NotFoundComponent,
        data: { pageDepth: 'NotFound' },
      },
    ],
  },
];
