import { Injectable, Inject } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SeServiceBase } from '@se-service'
import { SeApi } from '@se-api'
import { SeFeDateTime } from 'se-fe-date-time'
import { Program, ParticipantType, ProgramType, ProgramParticipantType } from '@app-services'
import { ProgramSignup, ProgramSignupPersonaType } from './program-signup'
import { dateRange } from '../se-utils/date-range'

export interface ProgramSignupModuleField {
  key: string
  required: boolean
}

export interface ProgramSignupModule {
  grouping: string
  key: string
  fields: ProgramSignupModuleField[]
}

@Injectable({ providedIn: 'root' })
export class ProgramSignupService extends SeServiceBase<ProgramSignup> {

  public endpoint = 'v3/program/program_signups'
  public Model: any = ProgramSignup

  constructor(
    public api: SeApi,
    public translate: TranslateService,
  ) {
    super(api)
  }

  public reportUrl(ps: ProgramSignup): Promise<string> {
    const id = ps.signup_id
    const type = `${ps.signup_system}:${ps.signup_type}`
    switch (type) {
      case 'Ngin:FormBuilder::Survey': return this.getNginSurvey(id).then(s => s.result.survey_url)
      default: return Promise.resolve('')
    }
  }

  public signupUrl(ps: ProgramSignup): Promise<string> {
    const id = ps.signup_id
    const type = `${ps.signup_system}:${ps.signup_type}`
    switch (type) {
      case 'Ngin:FormBuilder::Survey': return this.getNginSurvey(id).then(s => s.result.welcome_url)
      default: return Promise.resolve('')
    }
  }

  private getNginSurvey(id: number): Promise<any> {
    return this.api.get(`surveys/${id}`, this.v2Headers).toPromise()
  }

  public dateRangeDisplay(programSignup: ProgramSignup): string {
    return dateRange(programSignup.open_date, programSignup.close_date).shortDate
  }

  public defaultInstance(program: Program, ppt: ProgramParticipantType): ProgramSignup {
    const participantType = ppt.participant_type
    const playerOrTeam = participantType.player || participantType.team
    const campClinic = program.program_type.campClinic

    return ProgramSignupService.createInstance(ProgramSignup, {
      name: this.defaultName(program, participantType),
      program_participant_type: ppt,
      program_participant_type_id: ppt.id,
      allow_multiple_options: (campClinic && playerOrTeam) ? null : false,
      allowed_persona_types: this.defaultPersonaTypes(participantType)
    })
  }

  private defaultPersonaTypes(participantType: ParticipantType): ProgramSignupPersonaType[] {
    if (participantType.team) return ['team']
    return participantType.player ? null : ['self'] // players will be set later
  }

  public defaultName(p: Program, pt: ParticipantType): string {
    const tokens = { program: p.name, participantType: pt.name }
    return this.translate.instant('ADD_PROGRAM.SIGNUP_NAME.default', tokens)
  }

  private get v2Headers(): any {
    return {
      headers: {
        Accept: 'application/vnd.ngin-api.v2, application/json'
      }
    }
  }

}
