import { SeMappableModel } from '../se-service/se-mappable-model'

export class ProgramSignupPaymentTermOption extends SeMappableModel{
  public id: number
  public payment_term_uuid: string
  public payment_term_id: number
  public payment_term_name: string
  public payment_term_due_dates: string[]
  public created_at: Date
  public updated_at: Date
  public program_signup_id: number
  /* tslint:disable:variable-name */

  public isDueInFull(): boolean {
    return this.payment_term_name === 'Due in Full'
  }
}
