import { Injectable } from '@angular/core'
import { SeServiceBase } from '../se-service'
import { ParticipantType } from './participant-type'

@Injectable({ providedIn: 'root' })
export class ParticipantTypeService extends SeServiceBase<ParticipantType> {

  public endpoint = 'v3/program/participant_types'
  public Model: any = ParticipantType

}
