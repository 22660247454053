export const gtmConfig = {
  id: 'GTM-KGG8ZWR',
  gtm_auth: 'X5qhiYLYzREwsNTNK36Mwg',
  gtm_preview: 'env-2',
}

export function gtmInit() {
  const config = { ...gtmConfig, gtm_cookies_win: 'x' }
  const params = Object.keys(config).map(k => `${k}=${config[k]}`).join('&')
  const dl = window.dataLayer = window.dataLayer || []
  const d = document
  const s1 = d.getElementsByTagName('script')[0]
  const s = d.createElement('script')

  // Emit GTM start DL event
  dl.push({ 'gtm.start': new Date().getTime(), event:'gtm.js' })

  // Create script tag
  s.async = true
  s.src = `https://www.googletagmanager.com/gtm.js?${params}`
  s1.parentNode.insertBefore(s, s1)
}
