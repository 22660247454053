import { ModuleWithProviders, NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { TranslationsLoader } from './translations-loader'
import { TranslationsLanguages, TranslationsPrefix } from './translations-injection-tokens'

@NgModule({
  imports: [TranslateModule.forRoot({ defaultLanguage: 'en' })],
  exports: [TranslateModule],
  providers: [TranslationsLoader]
})
export class TranslationsRootModule {}

@NgModule({
  imports: [TranslateModule.forChild({ extend: true })],
  exports: [TranslateModule],
  providers: [TranslationsLoader]
})
export class TranslationsChildModule {}

export class TranslationsModule {

  public static forRoot(langs: string[]): ModuleWithProviders<TranslationsModule> {
    return {
      ngModule: TranslationsRootModule,
      providers: [
        {
          provide: TranslationsLanguages,
          useValue: langs
        },
        {
          provide: TranslationsPrefix,
          useValue: '/assets/translations'
        }
      ]
    }
  }

  public static forChild(name: string): ModuleWithProviders<TranslationsModule> {
    return {
      ngModule: TranslationsChildModule,
      providers: [
        {
          provide: TranslationsPrefix,
          useValue: `/assets/${name}/translations`
        }
      ]
    }
  }

}
