import { SeMappableModel } from '../se-service'
import startCase from 'lodash/startCase'

export enum ParticipantTypes {
  Player = 1,
  Coach = 2,
  Team = 3,
  Volunteer = 4,
}

export class ParticipantType extends SeMappableModel {
  public id: number
  public key: string
  public name: string

  public get player(): boolean {
    return this.key === 'player'
  }

  public get coachStaff(): boolean {
    return this.key === 'coach_staff'
  }

  public get team(): boolean {
    return this.key === 'team'
  }

  public get volunteer(): boolean {
    return this.key === 'volunteer'
  }

  public get eventPrefix(): string {
    return startCase(this.key.split('_')[0])
  }

}
