import { Inject, Injectable } from '@angular/core'
import { Observable, from } from 'rxjs'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from '@angular/router'

import { SeAuthService, RoleAssignment } from 'se-fe-auth-support'
import { FeatureToggleService } from '../feature-toggle'
import { AppPermissionConfig } from './app-permission.types'
import { CURRENT_ORG_ID } from '../current-org-id.provider'

@Injectable({ providedIn: 'root' })
export class AppPermission implements CanActivate, CanActivateChild {

  // Template Example: *ngIf="MyPermission.enabled$ | async"
  public get enabled$(): Observable<boolean> {
    return this._enabled$ = this._enabled$ || from(this.checkPermission())
  }

  public canActivate = this.guard
  public canActivateChild = this.guard

  protected config: AppPermissionConfig = {}
  private _enabled$: Observable<boolean>

  constructor(
    @Inject(CURRENT_ORG_ID) protected currentOrgId: string,
    protected authService: SeAuthService,
    protected featureToggleService: FeatureToggleService,
  ) {
    // noop
  }

  private guard(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.checkPermission()
  }

  // Exposed for tests
  public async checkPermission(): Promise<boolean> {
    if (await this.requiredFeaturesEnabled()) {
      if (await this.adminCanAccess()) {
        return true
      } else if (await this.rolesAreValid()) {
        return true
      }
    }

    return false
  }

  private async requiredFeaturesEnabled(): Promise<boolean> {
    if (!this.config.featureToggles) return true
    return this.featureToggleService.allEnabledForOrg(this.currentOrgId, this.config.featureToggles)
  }

  private adminCanAccess(): Promise<boolean> {
    return this.config.allowAdmins ? this.authService.isAdmin() : Promise.resolve(false)
  }

  private async rolesAreValid(): Promise<boolean> {
    if (!this.config.allowRoles) return true

    for (const resource in this.config.allowRoles) {
      if (await this.hasAnyResourceRoles(resource)) return true
    }

    return false
  }

  private async hasAnyResourceRoles(resource): Promise<boolean> {
    const resourceRoles = [].concat(this.config.allowRoles[resource])
    const roleAssignment = this.resourceRoleAssignment(resource)

    for (const role of resourceRoles) {
      const resourceRoleAssignment = { role, ...roleAssignment }
      if (await this.authService.hasRole(resourceRoleAssignment)) return true
    }

    return false
  }

  private resourceRoleAssignment(resource) {
    const resourceData = resource.split(':')
    const resourceType = resourceData[1] || resourceData[0]
    const resourceId = this.resourceId(resourceType)
    const roleAssignment: Partial<RoleAssignment> = { resource_type: resourceType }

    if (resourceId !== undefined) roleAssignment.resource_id = resourceId
    if (resourceData[1]) roleAssignment.resource_system = resourceData[0]

    return roleAssignment
  }

  private resourceId(resourceType: string): string {
    switch (resourceType) {
      case 'Platform': return '' // Platform resource ids are all empty strings
      case 'Organization': return this.currentOrgId.toString()
    }
  }

}
