import { ModuleWithProviders, NgModule } from '@angular/core'
import { SeApi } from './se-api'

@NgModule({
  providers: [
    SeApi
  ]
})
export class SeApiModule {
  public static forRoot(
    environment: any
  ): ModuleWithProviders<SeApiModule> {
    return {
      ngModule: SeApiModule,
      providers: [
        { provide: 'env', useValue: environment },
      ],
    }
  }
}
