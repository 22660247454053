import { Injectable } from '@angular/core'
import { SeMappableModel, SeServiceBase, SeServiceFindEvent } from '../se-service'

// We only care about the customer_pays_fees value here currently, can update this interface later if needed
export class Account extends SeMappableModel {
  public tenant_key: string
  public approved: boolean
  public auto_payouts: boolean
  public business_profile: {
    name: string
    url: string
  }
  public company: any
  public customer_pays_fee: boolean
  public email: string
  public financial_contact_email: string
  public financial_contact_name: string
  public fiscal_start_date: string
  public onboarding_user_id: number
  public reference_id: string
  public requirements: any
  public statement_descriptor: string
  public status: string
  public transaction_fee: string
  public variable_rate: string
  public under_review: boolean
  public merchant_account_id: number
  public persons: any[]
  public external_account: any
}

@Injectable({ providedIn: 'root' })
export class AccountService extends SeServiceBase<Account> {

  public endpoint = 'onboarding/accounts'
  public Model: any = Account

  public find(id: string | number, options: any = {}): Promise<Account> {
    const request = this.api.get(`${this.endpoint}/organization:${id}`, this.includeApiOptions(options))
    return this.promiseModel(request, SeServiceFindEvent)
  }

}
