import { EventEmitter, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SeApi } from '../se-api'
import { SeMapper } from './se-mapper'
import { SeMappableModel } from './se-mappable-model'
import { merge } from 'lodash'

import {
  SeServiceEvent,
  SeServiceCreateEvent,
  SeServiceUpdateEvent,
  SeServiceDestroyEvent,
  SeServiceFindEvent,
  SeServiceFindAllEvent,
} from './se-service-events'

export interface SeCollection<Model> extends Array<Model> {
  pagination?: any
}

@Injectable({ providedIn: 'root' })
export abstract class SeServiceBase<Model extends SeMappableModel> {

  constructor(public api: SeApi) {
    // noop
  }

  public endpoint = '' // Override endpoint for your service
  public Model: new(...args: any[]) => Model // Override Model for your service
  public apiOptions: any

  // NOTE: apparently there is no guarantee event emitters will use rxjs subject forever. We should consider
  // switching this to a custom implementation when pulling it into se-fe-angular-services.
  public events: EventEmitter<SeServiceEvent<any>> = new EventEmitter<SeServiceEvent<any>>()

  static createInstance<M>(model: new(...args: any[]) => M, data: Partial<M>): M {
    return new (model as any)(data)
  }

  static createCollection<M>(model: new(...args: any[]) => M, data: Partial<M>[], pagination?: any): SeCollection<M> {
    const collection = ([].concat(data || [])).map(modelData => this.createInstance(model, modelData)) as SeCollection<M>
    collection.pagination = pagination
    return collection
  }

  public find(id: string | number, options: any = {}): Promise<Model> {
    const request = this.api.get(`${this.endpoint}/${id}`, this.includeApiOptions(options))
    return this.promiseModel(request, SeServiceFindEvent)
  }

  public findAll(params?: any, options?: any): Promise<SeCollection<Model>> {
    // shift params onto options, merging with any existing options.params
    if (!options) options = {}
    options.params = merge({}, options.params, params)
    const request = this.api.get(this.endpoint, this.includeApiOptions(options))
    return this.promiseCollection(request, SeServiceFindAllEvent)
  }

  public save(data: Partial<Model>, options: any = {}): Promise<Model> {
    const id = data.id
    const method = id ? 'put' : 'post'
    const url = id ? `${this.endpoint}/${id}` : this.endpoint
    const request = this.api[method](url, data, this.includeApiOptions(options))
    return this.promiseModel(request, id ? SeServiceUpdateEvent : SeServiceCreateEvent)
  }

  public destroy(data: Partial<Model>, options: any = {}): Promise<any> {
    if (data || data.id) {
      const request = this.api.delete(`${this.endpoint}/${data.id}`, this.includeApiOptions(options))
      return this.promiseModel(request, SeServiceDestroyEvent, data)
    } else {
      return Promise.reject('Cannot destroy a model without an ID')
    }
  }

  public collection(data: Partial<Model>[], pagination?: any): SeCollection<Model> {
    return SeServiceBase.createCollection<Model>(this.Model, data, pagination)
  }

  public instance(data: Partial<Model>): Model {
    return SeServiceBase.createInstance<Model>(this.Model, data)
  }

  protected promiseModel(request: Observable<any>, eventClass = SeServiceEvent, destroyed?: Partial<Model>): Promise<Model> {
    return request
      .toPromise()
      .then(resp => (resp?.result?.id || resp?.result?.reference_id) ? this.instance(resp.result) : resp)
      .then(model => this.emitEvent<Model>(eventClass, model || destroyed))
  }

  protected promiseCollection(request: Observable<any>, eventClass = SeServiceEvent): Promise<SeCollection<Model>> {
    return request
      .toPromise()
      .then(data => this.parseFindAll(data))
      .then(collection => this.emitEvent<Model[]>(eventClass, collection))
  }

  protected parseFindAll(data: any): SeCollection<Model> {
    const metadata = data.metadata || {}
    return this.collection(data.result, metadata.pagination)
  }

  protected includeApiOptions(options?: any): any {
    return { ...this.apiOptions, ...options }
  }

  protected emitEvent<M>(eventClass: new (m: M) => SeServiceEvent<M>, model: M): M {
    this.events.emit(new eventClass(model))
    return model
  }
}
