import { SeMappableModel } from '../se-service'

export class ParticipantSummary extends SeMappableModel {
  public id: number
  public type: string
  public active: number
  public pending: number
  public total: number

  public get anyActive(): boolean {
    return this.active > 0
  }

  public get anyPending(): boolean {
    return this.pending > 0
  }
  public get anyParticipants(): boolean {
    return this.total > 0
  }

}
