import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

// TODO: figure out where this is used and make V1, V2, and V3 interfaces?
export interface SeApiResponse<T> {
  metadata?: {
    trace_id: string
    current_user: any
    pagination: any
  }
  error?: {
    status: number
    messages: string[]
  }
  result: T
  [index: string]: any
}

@Injectable({ providedIn: 'root' })
export class SeApi {

  constructor(
    @Inject('env') private env: any,
    private http: HttpClient,
  ) {
    // noop
  }

  public get(path: string, options?: any): Observable<any> {
    return this.http.get(this.uri(path), this.getOptions(options))
  }

  public post(path: string, body: any, options?: any): Observable<any> {
    return this.http.post(this.uri(path), body, this.getOptions(options))
  }

  public put(path: string, body: any, options?: any): Observable<any> {
    return this.http.put(this.uri(path), body, this.getOptions(options))
  }

  public delete(path: string, options?: any): Observable<any> {
    return this.http.delete(this.uri(path), this.getOptions(options))
  }

  public uri(path: string): string {
    return this.env.apiUrl + encodeURI(path)
  }

  private getOptions(options?: any): any {
    options = options || {}
    options.withCredentials = true
    options.headers = options.headers || {}
    options.headers.Accept = options.headers.Accept || 'application/json'
    return options
  }
}
