import { SeMappableModel, SeServiceBase } from '../se-service'
import { ParticipationLimitConfiguration } from './participation-limit-configuration'
import { ParticipationLimitConfigurationService } from './participation-limit-configuration.service'
import { ProgramParticipantType } from './program-participant-type'

export class ParticipationOption extends SeMappableModel {
  public id: number
  public disabled: boolean
  public name: string
  public price_cents: number
  public upfront_amount_cents: number
  public program_participant_type: ProgramParticipantType
  public program_participant_type_id: number
  public participation_limit_configuration: ParticipationLimitConfiguration

  public get team(): boolean {
    return this.program_participant_type?.participant_type?.id === 3
  }

  public anyLimitsSet(): boolean {
    return this.participation_limit_configuration.anyLimitsSet()
  }

  public upfrontAmountEligible(): boolean {
    return this.price_cents >= 2500
  }

  public upfrontAmountSet(): boolean {
    return this.upfrontAmountEligible() && (this.upfront_amount_cents > 0 || this.upfront_amount_cents === null)
  }

  public deserialize(data: any): any {
    const ppt = data.program_participant_type
    if (ppt) data.program_participant_type = SeServiceBase.createInstance<ProgramParticipantType>(ProgramParticipantType, ppt)
    return ParticipationLimitConfigurationService.createNestedPLC(data, 'ParticipationOption')
  }
}
