import { SeMappableModel } from '../se-service/se-mappable-model'

export interface EligibilityRule {
  created_at?: Date
  display_name?: string
  eligibility_rule_set_id?: string
  id?: string
  linked_eligibility_rule_set_id?: string
  originator_id: string
  originator_system: string
  originator_type: string
  trigger?: boolean
  updated_at?: Date
}

export class EligibilityRuleSet extends SeMappableModel{
  boss_organization_id: number
  created_at?: Date
  eligibility_rules: EligibilityRule[]
  name: string
  updated_at?: Date
}
