import { SeMappableModel, SeServiceBase } from '@se-service'
import { flattenIds } from '@se-utils'
import { ProgramType } from './program-type'
import { ProgramParticipantType } from './program-participant-type'
import { ParticipantSummary } from '@app-services'
import { dateRange } from '../se-utils/date-range'

export type ProgramDate = string
export type ProgramSportKey = string
export type ProgramStatus = string

export class Program extends SeMappableModel {
  public end_date: ProgramDate
  public id: number
  public name: string
  public organization_id: number
  public program_type_id?: number
  public program_type?: ProgramType
  public sport_key: ProgramSportKey
  public timezone: string
  public start_date: ProgramDate
  public status: ProgramStatus
  public program_participant_types: ProgramParticipantType[]
  public participant_summary: ParticipantSummary // fetch and attached client side, for now

  public get isComplete(): boolean {
    return this.status === 'complete'
  }

  public get isIncomplete(): boolean {
    return this.status === 'incomplete'
  }

  public get isIncompleteFinancials(): boolean {
    return this.status === 'incomplete_financials'
  }

  public get isArchived(): boolean {
    return this.status === 'archived'
  }

  public get dateRangeDisplay(): string {
    const dt = dateRange(this.start_date, this.end_date)
    return this.isLongDuration ? dt.month : dt.shortDate
  }

  public get canDelete(): boolean {
    return this.isArchived ? false : this.participant_summary?.active === 0 // will return false if summary is not present
  }

  public get isLongDuration(): boolean {
    return ProgramType.LONG_DURATION_IDS.includes(this.program_type_id)
  }

  public deserialize(data: any): Partial<Program> {
    const ppts = data.program_participant_types
    data.program_type = SeServiceBase.createInstance<ProgramType>(ProgramType, data.program_type)
    data.program_participant_types = SeServiceBase.createCollection<ProgramParticipantType>(ProgramParticipantType, ppts)
    return flattenIds(data, 'program_type')
  }

}
