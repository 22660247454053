export class SeServiceEvent<Model> {

  public affected: Model

  constructor(affected: Model) {
    this.affected = affected
  }

}

export class SeServiceSaveEvent<Model> extends SeServiceEvent<Model> { }
export class SeServiceCreateEvent<Model> extends SeServiceSaveEvent<Model> { }
export class SeServiceUpdateEvent<Model> extends SeServiceSaveEvent<Model> { }
export class SeServiceDestroyEvent<Model> extends SeServiceEvent<Model> { }
export class SeServiceFindEvent<Model> extends SeServiceEvent<Model> { }
export class SeServiceFindAllEvent<Model> extends SeServiceEvent<Model> { }
