import { Injectable, Inject } from '@angular/core'
import { SeServiceBase } from '../se-service'
import { Timezone } from './timezone'

export interface TimezoneOptionGroup {
  label: string
  value: TimezoneOption[]
}

export interface TimezoneOption {
  label: string
  value: string
}

const US_CANADA = ['United States', 'Canada']

@Injectable({ providedIn: 'root' })
export class TimezoneService extends SeServiceBase<Timezone> {

  public endpoint = 'time_zones'
  public Model: any = Timezone
  public apiOptions: any = {
    headers: {
      Accept: 'application/vnd.ngin-api.v2, application/json'
    }
  }

  private optionsPromise: Promise<TimezoneOptionGroup[]>

  public getOptions(): Promise<TimezoneOptionGroup[]> {
    if (!this.optionsPromise) this.optionsPromise = this.findAll().then(zones => this.buildOptions(zones))
    return this.optionsPromise
  }

  private buildOptions(zones: Timezone[]): TimezoneOptionGroup[] {
    const usAndCanada = { label: 'United States and Canada', value: [] }
    const international = { label: 'International', value: [] }
    zones.forEach(zone => {
      const group = this.isUsCanada(zone) ? usAndCanada.value : international.value
      const label = `(UTC ${zone.offset}) ${zone.name}`
      group.push({ label, value: zone.identifier })
    })
    return [usAndCanada, international]
  }

  private isUsCanada(zone) {
    return US_CANADA.includes(zone.country_name)
  }

}
