import { Injectable, Inject } from '@angular/core'
import { SeServiceBase } from '../se-service'
import { ProgramType } from './program-type'

@Injectable({ providedIn: 'root' })
export class ProgramTypeService extends SeServiceBase<ProgramType> {

  public endpoint = 'v3/program/program_types'
  public Model: any = ProgramType
  private findAllPromise: Promise<ProgramType[]>

  // Cache this since it never changes
  public findAll(): Promise<ProgramType[]> {
    return this.findAllPromise = this.findAllPromise || super.findAll()
  }

}
