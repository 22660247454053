import { SeMappableModel } from '../se-service'
import { ProgramParticipantType } from './program-participant-type'
import { ProgramSignupPaymentTermOption } from './program-signup-payment-term-option'

export type ProgramSignupDate = string
export type ProgramSignupStatus = 'incomplete' | 'enabled' | 'disabled' | 'archived'
export type ProgramSignupId = number
export type ProgramSignupType = 'FormBuilder::Survey'
export type ProgramSignupSystem = 'Ngin'
export type ProgramSignupPersonaType = 'self' | 'other' | 'team'

export class ProgramSignup extends SeMappableModel {
  static ALLOWED_PERSONA_TYPE = {
    Self: 'self',
    Other: 'other',
    Team: 'team',
  }

  public allow_multiple_options: boolean
  public allowed_persona_types: ProgramSignupPersonaType[]
  public close_date_auto: boolean
  public close_date: ProgramSignupDate
  public created_at: ProgramSignupDate
  public email_results_to: string
  public organization_pays_fees: boolean
  public id: number
  public name: string
  public open_date_auto: boolean
  public open_date: ProgramSignupDate
  public program_participant_type_id?: number
  public program_participant_type?: ProgramParticipantType
  public signup_id: ProgramSignupId
  public signup_system: ProgramSignupSystem
  public signup_type: ProgramSignupType
  public reason_sync_disabled: string
  public status: ProgramSignupStatus
  public updated_at: ProgramSignupDate
  public program_signup_payment_term_options: ProgramSignupPaymentTermOption[]

  public get requiredDataSet(): boolean {
    return (
      this.nameSet && this.personaTypesSet && this.multipleOptionsSet && this.processingFeesSet && this.datesSet && this.notificationsSet
    )
  }

  public get signupSettingsSet(): boolean {
    return (
      this.nameSet && this.personaTypesSet && this.multipleOptionsSet && this.datesSet && this.notificationsSet
    )
  }

  public get nameSet(): boolean {
    return !!this.id && !!this.name
  }

  public get datesSet(): boolean {
    return !!this.open_date
  }

  public get personaTypesSet(): boolean {
    const apt = this.allowed_persona_types || []
    return !!apt.length
  }

  public get processingFeesSet(): boolean {
    return typeof this.organization_pays_fees === 'boolean'
  }

  public get multipleOptionsSet(): boolean {
    return typeof this.allow_multiple_options === 'boolean'
  }

  public get notificationsSet(): boolean {
    return typeof this.email_results_to === 'string'
  }

  public get inadequateReasonSet(): boolean {
    const reason = this.reason_sync_disabled
    return reason && reason !== 'unknown'
  }

  public get canEdit(): boolean {
    return this.status !== 'archived'
  }

  public get hasSignup(): boolean {
    return !!this.signup_id
  }

  public get canDisable(): boolean {
    return this.status === 'enabled' && !this.beforeOpenDate && !this.afterCloseDate
  }

  public get canPublish(): boolean {
    return this.status === 'disabled' || (this.status === 'enabled' && (this.beforeOpenDate || this.afterCloseDate))
  }

  public get beforeOpenDate(): boolean {
    return this.open_date && new Date().toISOString() < this.open_date
  }

  public get afterCloseDate(): boolean {
    return this.close_date && new Date().toISOString() > this.close_date
  }
}
