import { SeMappableModel, SeServiceBase } from '../se-service'
import { OrganizationSport } from './organization-sport'

export class Organization extends SeMappableModel {

  /* tslint:disable:variable-name */
  public id: number
  public name: string
  public processing_fee: string
  public sports: any
  public timezone: string
  public transaction_fee: string
  /* tslint:enable:variable-name */

  public deserialize(data: any): Partial<Organization> {
    data.sports = this.sportCollection(data.sports)
    return data
  }

  private sportCollection(sports: Partial<OrganizationSport>[]): OrganizationSport[] {
    return sports && SeServiceBase.createCollection<OrganizationSport>(OrganizationSport, sports)
  }

}
