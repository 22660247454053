import { Injectable } from '@angular/core'
import { SeServiceBase } from '../se-service'
import { ElectronicDocument } from './electronic-document'

@Injectable({ providedIn: 'root' })
export class ElectronicDocumentService extends SeServiceBase<ElectronicDocument> {

  public endpoint = 'v3/electronic_documents'
  public Model: any = ElectronicDocument

}
