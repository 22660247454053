import { Injectable } from '@angular/core'
import { SeServiceBase } from '../se-service'
import { ParticipantSummary } from './participant-summary'

@Injectable({ providedIn: 'root' })
export class ParticipantSummaryService extends SeServiceBase<ParticipantSummary> {

  public endpoint = 'v3/program/participants/summary'
  public Model: any = ParticipantSummary

  public byParticipationOptionId(id: number): Promise<ParticipantSummary> {
    return this.fetch({ participation_option_id: id })
  }

  public byProgramAndParticipantTypeIds(pid: number, ptid: number): Promise<ParticipantSummary> {
    return this.fetch({ program_id: pid, participant_type_id: ptid })
  }

  public byProgramId(pid: number): Promise<ParticipantSummary> {
    return this.fetch({ program_id: pid })
  }

  private fetch(params: any): Promise<ParticipantSummary> {
    return this.api
      .get(this.endpoint, this.includeApiOptions({ params }))
      .toPromise()
      .then(data => this.instance(data.result))
  }

}
