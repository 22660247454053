import { Injectable, ViewChild } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { SeFeModalComponent } from 'se-fe-modal'
import { ConfirmActionFn, ConfirmAction, ConfirmConfig } from './confirm.types'

@Injectable({ providedIn: 'root' })
export class ConfirmService {

  events = new BehaviorSubject<ConfirmConfig>(undefined)

  public confirm(config: ConfirmConfig): void {
    this.events.next(config)
  }
}
